import React, { useState, useEffect } from 'react';
import { fetchWeeklySelections, fetchCurrentNflscGameFixtures } from '../../services/api';
import Card from '../common/Card';
import LoadingSpinner from '../common/LoadingSpinner';
import styles from './WeeklySelections.module.css';
import { formatHandicap } from '../../utils/formatHandicap';

const WeeklySelections = () => {
  const [weeklySelections, setWeeklySelections] = useState([]);
  const [currentGame, setCurrentGame] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadData = async () => {
      try {
        setLoading(true);
        const { currentGame } = await fetchCurrentNflscGameFixtures();
        setCurrentGame(currentGame);
        if (!currentGame) {
          throw new Error('No active NFLSC game found');
        }
        const selections = await fetchWeeklySelections(currentGame.name);
        const sortedSelections = selections.sort((a, b) => b.score - a.score);
        setWeeklySelections(sortedSelections);
      } catch (err) {
        console.error('Error loading data:', err);
        setError('Failed to load weekly selections. Please try again later.');
      } finally {
        setLoading(false);
      }
    };
    loadData();
  }, []);

  const getStatusClass = (result) => {
    switch (result) {
      case 'Win': return styles.win;
      case 'Loss': return styles.loss;
      case 'Push': return styles.push;
      default: return styles.pending;
    }
  };

  if (loading) return <LoadingSpinner />;
  if (error) return <Card><div className={styles.error}>{error}</div></Card>;

  const isDeadlinePassed = currentGame && new Date() > new Date(currentGame.deadline);

  return (
    <Card>
      <div className={styles.container}>
        <h1 className={styles.title}>Weekly Selections - {currentGame?.name}</h1>
        
        {currentGame && (
          <div className={styles.gameInfo}>
            <p>Deadline: {new Date(currentGame.deadline).toLocaleString()}</p>
          </div>
        )}

        {!isDeadlinePassed ? (
          <p className={styles.message}>Selections will be available after the deadline has passed.</p>
        ) : weeklySelections.length > 0 ? (
          <div className={styles.tableWrapper}>
            <table className={styles.selectionsTable}>
              <thead>
                <tr>
                  <th className={styles.rankColumn}>#</th>
                  <th className={styles.userColumn}>User</th>
                  <th className={styles.picksColumn}>Picks</th>
                </tr>
              </thead>
              <tbody>
                {weeklySelections.map((userSelection, index) => (
                  <tr key={index}>
                    <td className={styles.rankColumn}>{index + 1}</td>
                    <td className={styles.userColumn}>
                      <span className={styles.userName}>{userSelection.user.displayName}</span>
                      <span className={styles.userScore}>({userSelection.score})</span>
                    </td>
                    <td className={styles.picksColumn}>
                      <div className={styles.picksContainer}>
                        {userSelection.selections.map((selection, selIndex) => (
                          <span key={selIndex} className={`${styles.pick} ${getStatusClass(selection.result)}`}>
                            {selection.selectedTeam} ({formatHandicap(selection.handicap)})
                          </span>
                        ))}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <p className={styles.message}>No selections available for this week.</p>
        )}
      </div>
    </Card>
  );
};

export default WeeklySelections;
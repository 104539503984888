import { getToken } from '../utils/auth';

const API_BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:5002/api';
const API_URL = process.env.REACT_APP_API_URL || 'https://amfootball-selections-fe626b7f84f7.herokuapp.com/api';

async function fetchWithAuth(url, options = {}) {
  const token = localStorage.getItem('token');
  const headers = {
    'Content-Type': 'application/json',
    ...options.headers,
  };

  if (token) {
    headers['Authorization'] = `Bearer ${token}`;
  }

  const response = await fetch(url, { ...options, headers });

  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.message || 'An error occurred');
  }

  return response.json();
}

export const register = async (displayName, email, password) => {
  console.log('Attempting registration with:', { displayName, email });
  try {
    const url = `${API_BASE_URL}/register`;
    console.log('Registration URL:', url);
    
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ displayName, email, password }),
    });
    console.log('Registration response status:', response.status);
    
    const data = await response.json();
    console.log('Registration response:', data);
    
    if (!response.ok) {
      throw new Error(data.message || 'Registration failed');
    }
    
    return data;
  } catch (error) {
    console.error('Registration error:', error);
    throw error;
  }
};

export const login = async (email, password) => {
  const response = await fetch(`${API_BASE_URL}/login`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ email, password }),
  });
  
  const data = await response.json();
  
  if (!response.ok) {
    throw new Error(data.message || 'Failed to log in');
  }
  
  return data;
};

export const logout = () => {
  localStorage.removeItem('token');
};

export const fetchTeams = async () => {
  const response = await fetch(`${API_BASE_URL}/teams`, {
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`,
    },
  });
  if (!response.ok) throw new Error('Failed to fetch teams');
  return response.json();
};

export const fetchPlayers = async () => {
  const response = await fetch(`${API_BASE_URL}/players`, {
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`,
    },
  });
  if (!response.ok) throw new Error('Failed to fetch players');
  return response.json();
};

export const submitSelections = async (selections) => {
  try {
    const response = await fetch(`${API_BASE_URL}/selections`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      },
      body: JSON.stringify({ selections })
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Failed to submit selections');
    }

    return response.json();
  } catch (error) {
    console.error('Error submitting selections:', error);
    throw error;
  }
};

export const fetchUserSelections = async () => {
  try {
    const response = await fetchWithAuth(`${API_BASE_URL}/my-selections`);
    console.log('Raw response from fetchWithAuth:', response);
    
    if (typeof response === 'string') {
      return JSON.parse(response);
    } else if (response instanceof Response) {
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to fetch selections');
      }
      return response.json();
    } else {
      return response;
    }
  } catch (error) {
    console.error('Error in fetchUserSelections:', error);
    throw error;
  }
};

export const updateUserSelections = async (selectionId, selections) => {
  try {
    const response = await fetch(`${API_BASE_URL}/selections/${selectionId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      },
      body: JSON.stringify({ selections })
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Failed to update selections');
    }

    return response.json();
  } catch (error) {
    console.error('Error updating selections:', error);
    throw error;
  }
};

export const deleteUserSelection = async (selectionId) => {
  return fetchWithAuth(`${API_BASE_URL}/selections/${selectionId}`, {
    method: 'DELETE',
  });
};

export const fetchAllSelections = async () => {
  try {
    const response = await fetch(`${API_BASE_URL}/selections`, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Failed to fetch selections');
    }

    return response.json();
  } catch (error) {
    console.error('Error fetching all selections:', error);
    throw error;
  }
};

export const getCurrentUser = async () => {
  const response = await fetch(`${API_BASE_URL}/user`, {
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`
    }
  });
  if (!response.ok) throw new Error('Failed to get current user');
  const data = await response.json();
  console.log('getCurrentUser response:', data);
  return data;
};

export const createNewGame = async (name) => {
  const response = await fetch(`${API_BASE_URL}/admin/games`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('token')}`
    },
    body: JSON.stringify({ name })
  });
  if (!response.ok) throw new Error('Failed to create new game');
  return response.json();
};

const handleResponse = async (response) => {
  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.message || 'An error occurred');
  }
  return response.json();
};

export const getCurrentGame = async () => {
  console.log('Frontend: Calling getCurrentGame');
  try {
    const response = await fetch(`${API_BASE_URL}/games/current`, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    });
    console.log('Frontend: getCurrentGame response status:', response.status);
    if (response.status === 404) {
      console.log('Frontend: No active game found');
      return null;
    }
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    console.log('Frontend: getCurrentGame data:', data);
    return data;
  } catch (error) {
    console.error('Frontend: Error in getCurrentGame:', error);
    throw error;
  }
};

export const updateGameDeadline = async (gameId, newDeadline) => {
  return fetchWithAuth(`${API_BASE_URL}/admin/games/${gameId}/deadline`, {
    method: 'PUT',
    body: JSON.stringify({ deadline: newDeadline }),
  });
};

export const archiveGame = async (gameId) => {
  const response = await fetch(`${API_BASE_URL}/games/${gameId}/archive`, { method: 'POST' });
  if (!response.ok) throw new Error('Failed to archive game');
  return response.json();
};

export const getPlayers = async (teamId) => {
  try {
    const url = teamId 
      ? `${API_BASE_URL}/admin/team-players/${teamId}`
      : `${API_BASE_URL}/players`;
    
    const response = await fetch(url, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    });
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Failed to fetch players');
    }
    const players = await response.json();
    return players;
  } catch (error) {
    console.error('Error fetching players:', error);
    throw error;
  }
};

export const getTeams = async () => {
  const response = await fetch(`${API_BASE_URL}/teams`, {
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`
    }
  });
  if (!response.ok) throw new Error('Failed to fetch teams');
  return response.json();
};

export const syncFootballData = async () => {
  const response = await fetch(`${API_BASE_URL}/admin/sync-football-data`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('token')}`
    }
  });
  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Failed to sync data');
  }
  return response.json();
};

export const syncNflData = async () => {
  try {
    const response = await fetch(`${API_BASE_URL}/admin/sync-nfl-data`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    });
    console.log('Sync NFL Data response status:', response.status);
    
    const data = await response.json();
    
    if (!response.ok) {
      console.error('Sync NFL Data error:', data);
      throw new Error(data.message || 'Failed to sync NFL data');
    }
    
    return data;
  } catch (error) {
    console.error('Sync NFL Data catch block error:', error);
    throw error;
  }
};

export const updateScores = async (teamId, scoringPlayers) => {
  try {
    const response = await fetch(`${API_BASE_URL}/admin/update-scores`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      },
      body: JSON.stringify({ teamId, scoringPlayers })
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Failed to update scores');
    }

    return response.json();
  } catch (error) {
    console.error('Error updating scores:', error);
    throw error;
  }
};

export const markMatchComplete = async (teamId) => {
  try {
    const response = await fetch(`${API_BASE_URL}/admin/mark-match-complete`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      },
      body: JSON.stringify({ teamId })
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Failed to mark match as complete');
    }

    return response.json();
  } catch (error) {
    console.error('Error marking match as complete:', error);
    throw error;
  }
};

export const getGameweekResults = async () => {
  try {
    console.log('Fetching gameweek results...');
    const token = localStorage.getItem('token');
    console.log('Token available:', !!token);
    
    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), 10000); // 10 second timeout

    const response = await fetch(`${API_BASE_URL}/gameweek-results`, {
      headers: {
        'Authorization': `Bearer ${token}`
      },
      signal: controller.signal
    });

    clearTimeout(timeoutId);

    console.log('Gameweek results response status:', response.status);

    if (!response.ok) {
      const errorData = await response.json();
      console.error('Error response:', errorData);
      throw new Error(errorData.message || 'Failed to fetch gameweek results');
    }

    const data = await response.json();
    console.log('Gameweek results data:', data);
    return data;
  } catch (error) {
    console.error('Error fetching gameweek results:', error);
    if (error.name === 'AbortError') {
      throw new Error('Request timed out. Please try again later.');
    }
    throw error;
  }
};

export const fetchCurrentGameFixtures = async () => {
  try {
    const response = await fetch(`${API_BASE_URL}/current-game-fixtures`);
    if (!response.ok) {
      if (response.status === 404) {
        return { currentGame: null, matches: [] };
      }
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return await response.json();
  } catch (error) {
    console.error('Error fetching current game fixtures:', error);
    throw error;
  }
};

export const createNewNflGame = async (gameName) => {
  try {
    const response = await fetch('/api/admin/nfl-games', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getToken()}`,
      },
      body: JSON.stringify({ name: gameName }),
    });
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'HTTP error! status: ' + response.status);
    }
    return await response.json();
  } catch (error) {
    console.error('Error creating NFL game:', error);
    throw error;
  }
};

export const getCurrentNflGame = async () => {
  try {
    const response = await fetch('/api/nfl-games/current', {
      headers: {
        'Authorization': `Bearer ${getToken()}`,
      },
    });
    if (!response.ok) {
      if (response.status === 404) {
        // No game found, return null instead of throwing an error
        return null;
      }
      throw new Error('HTTP error! status: ' + response.status);
    }
    return await response.json();
  } catch (error) {
    console.error('Error fetching current NFL game:', error);
    return null;
  }
};

export const fetchNflMatches = async (gameweek) => {
  try {
    const response = await fetch(`/api/nfl/matches/${gameweek}`, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
      },
    });

    if (!response.ok) {
      throw new Error('Failed to fetch NFL matches');
    }

    return response.json();
  } catch (error) {
    console.error('Error fetching NFL matches:', error);
    throw error;
  }
};

export const submitNflSelection = async (matchId, selectedTeam) => {
  try {
    const response = await fetch(`${API_BASE_URL}/nfl/selection`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      },
      body: JSON.stringify({ matchId, selectedTeam })
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Failed to submit NFL selection');
    }

    return response.json();
  } catch (error) {
    console.error('Error submitting NFL selection:', error);
    throw error;
  }
};

export const fetchUserNflSelections = async () => {
  try {
    const response = await fetch(`${API_BASE_URL}/nfl/my-selections`, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    });
    
    if (!response.ok) {
      const text = await response.text();
      console.error('Error response:', text);
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    
    const data = await response.json();
    console.log('User NFL selections data:', data);
    return data;
  } catch (error) {
    console.error('Error in fetchUserNflSelections:', error);
    throw error;
  }
};

export const updateUserNflSelection = async (selectionId, newMatchId, newTeam) => {
  try {
    const response = await fetch(`${API_BASE_URL}/nfl/selections/${selectionId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      },
      body: JSON.stringify({ matchId: newMatchId, selectedTeam: newTeam })
    });

    if (!response.ok) {
      const text = await response.text();
      console.error('Error response:', text);
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return response.json();
  } catch (error) {
    console.error('Error updating NFL selection:', error);
    throw error;
  }
};

export const fetchAllNflSelections = async () => {
  try {
    const response = await fetch(`${API_BASE_URL}/nfl/selections`, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Failed to fetch all NFL selections');
    }

    return response.json();
  } catch (error) {
    console.error('Error fetching all NFL selections:', error);
    throw error;
  }
};

export const loadNflWeeklyMatches = async (gameweek) => {
  try {
    const response = await fetch(`/api/nfl/matches/gameweek/${gameweek}`, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
      },
    });
    if (!response.ok) {
      throw new Error('Failed to fetch NFL matches');
    }
    return response.json();
  } catch (error) {
    console.error('Error fetching NFL matches:', error);
    throw error;
  }
};

export const updateNflMatchResult = async (matchId, homeScore, awayScore) => {
  try {
    const response = await fetch(`${API_BASE_URL}/admin/update-nfl-result`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      },
      body: JSON.stringify({ matchId, homeScore, awayScore })
    });
    if (!response.ok) {
      throw new Error('Failed to update NFL match result');
    }
    return response.json();
  } catch (error) {
    console.error('Error updating NFL match result:', error);
    throw error;
  }
};

export const checkEligibility = async () => {
  try {
    const response = await fetch(`${API_BASE_URL}/nfl/check-eligibility`, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    });
    if (!response.ok) {
      throw new Error('Failed to check eligibility');
    }
    return response.json();
  } catch (error) {
    console.error('Error checking eligibility:', error);
    throw error;
  }
};

// Add a new function to create an NFL game
export const createNFLGame = async (gameData) => {
  try {
    const response = await fetch('/api/admin/nfl-games', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getToken()}`,
      },
      body: JSON.stringify(gameData),
    });
    if (!response.ok) {
      throw new Error('HTTP error! status: ' + response.status);
    }
    return await response.json();
  } catch (error) {
    console.error('Error creating NFL game:', error);
    throw error;
  }
};

export const submitNflscSelections = async (gameweek, selections) => {
  try {
    console.log('Submitting NFLSC selections:', { gameweek, selections });
    const response = await fetch('/api/nflsc/selections', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify({ gameweek, selections }),
    });

    console.log('Submit selections response:', response);

    if (!response.ok) {
      const errorText = await response.text();
      console.error('Error response:', errorText);
      throw new Error(errorText || 'Failed to submit selections');
    }

    return response.json();
  } catch (error) {
    console.error('submitNflscSelections error:', error);
    throw error;
  }
};

export const createNewNflscGame = async (name) => {
  const response = await fetch('/api/admin/nflsc/games', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('token')}`,
    },
    body: JSON.stringify({ name }),
  });

  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.message || 'Failed to create new NFLSC game');
  }

  return response.json();
};

export const getCurrentNflscGame = async () => {
  try {
    const response = await fetch('/api/nflsc/current-game', {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
      },
    });
    if (!response.ok) {
      const text = await response.text();
      console.error('Error response:', text);
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return response.json();
  } catch (error) {
    console.error('Error fetching current NFLSC game:', error);
    throw error;
  }
};

export const fetchSelections = async () => {
  try {
    const response = await fetch('/api/nflsc/my-selections', {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
      },
    });
    if (!response.ok) {
      throw new Error('Failed to fetch selections');
    }
    return response.json();
  } catch (error) {
    console.error('Error fetching selections:', error);
    throw error;
  }
};

export const fetchWeeklySelections = async (gameweek) => {
  try {
    console.log('Fetching NFLSC weekly selections...');
    const response = await fetch(`/api/nflsc/weekly-selections/${gameweek}`, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
      },
    });

    console.log('Fetch weekly selections response:', response);

    if (!response.ok) {
      const errorText = await response.text();
      console.error('Error response:', errorText);
      throw new Error(errorText || 'Failed to fetch weekly selections');
    }

    const responseText = await response.text();
    console.log('Response text:', responseText);

    try {
      const data = JSON.parse(responseText);
      console.log('Parsed weekly selections data:', data);
      return data;
    } catch (parseError) {
      console.error('Error parsing JSON:', parseError);
      throw new Error('Invalid JSON response');
    }
  } catch (error) {
    console.error('fetchWeeklySelections error:', error);
    throw error;
  }
};

export const fetchCurrentNflGameFixtures = async () => {
  try {
    const response = await fetch('/api/nfl/current-game-fixtures', {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
      },
    });

    if (!response.ok) {
      throw new Error('Failed to fetch current NFL game fixtures');
    }

    return response.json();
  } catch (error) {
    console.error('Error fetching NFL fixtures:', error);
    throw error;
  }
};

export const fetchCurrentNflscGameFixtures = async () => {
  try {
    const response = await fetch('/api/nflsc/current-game-fixtures', {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
      },
    });

    if (!response.ok) {
      throw new Error('Failed to fetch current NFLSC game fixtures');
    }

    return response.json();
  } catch (error) {
    console.error('Error fetching NFLSC fixtures:', error);
    throw error;
  }
};

export const checkUserSelections = async (gameweek) => {
  try {
    const response = await fetch(`/api/nflsc/user-selections/${gameweek}`, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
      },
    });
    if (!response.ok) {
      if (response.status === 404) {
        return null; // No selections found
      }
      throw new Error('Failed to check user selections');
    }
    return response.json();
  } catch (error) {
    console.error('Error checking user selections:', error);
    throw error;
  }
};

export const updateNflscSelections = async (gameweek, selections) => {
  try {
    console.log('Updating selections for gameweek:', gameweek);
    console.log('Selections to update:', selections);
    
    const response = await fetch(`/api/nflsc/selections/${gameweek}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify({ selections }),
    });
    
    if (!response.ok) {
      const errorData = await response.json();
      console.error('Server response:', errorData);
      throw new Error(errorData.message || 'Error updating selections');
    }
    
    return response.json();
  } catch (error) {
    console.error('Error updating NFLSC selections:', error);
    throw error;
  }
};

export const fetchNflMatchesByGameweek = async (gameweek) => {
  try {
    const response = await fetch(`/api/nfl/matches/${gameweek}`, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
      },
    });

    console.log('Fetch matches response:', response);

    if (!response.ok) {
      const errorText = await response.text();
      console.error('Error response:', errorText);
      throw new Error(errorText || 'Failed to fetch NFL matches');
    }

    const responseText = await response.text();
    console.log('Response text:', responseText);

    try {
      return JSON.parse(responseText);
    } catch (parseError) {
      console.error('Error parsing JSON:', parseError);
      throw new Error('Invalid JSON response');
    }
  } catch (error) {
    console.error('Error fetching NFL matches:', error);
    throw error;
  }
};

export const processNflscResults = async (gameweek) => {
  try {
    console.log(`Sending request to process NFLSC results for gameweek: ${gameweek}`);
    const response = await fetch(`/api/nflsc/process-results/${gameweek}`, {
      method: 'POST', 
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
      },
    });
    if (!response.ok) {
      const errorData = await response.text();
      console.error('Error response:', errorData);
      throw new Error(`Failed to process NFLSC results: ${errorData}`);
    }
    const data = await response.json();
    console.log('NFLSC results processed:', data);
    return data;
  } catch (error) {
    console.error('Error processing NFLSC results:', error);
    throw error;
  }
};

// For logged-in users resetting their password
export const resetPasswordLoggedIn = async (newPassword) => {
  const token = localStorage.getItem('token');
  const response = await fetch(`${API_URL}/reset-password`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
    body: JSON.stringify({ newPassword }),
  });

  if (!response.ok) {
    throw new Error('Failed to reset password');
  }

  return response.json();
};

// For forgotten password flow
export const requestPasswordReset = async (email) => {
  const response = await fetch(`${API_URL}/forgot-password`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ email })
  });

  if (!response.ok) {
    throw new Error('Failed to request password reset');
  }

  return response.json();
};

// For resetting password with a token (forgotten password flow)
export const resetPasswordWithToken = async (token, newPassword) => {
  console.log(`Attempting to reset password with token: ${token}`);
  const response = await fetch(`${API_URL}/reset-password/${token}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ password: newPassword }),
  });

  if (!response.ok) {
    console.error('Failed to reset password. Status:', response.status);
    throw new Error('Failed to reset password');
  }

  return response.json();
};

export const forgotPassword = async (email) => {
  const response = await fetch(`${API_URL}/forgot-password`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ email }),
  });

  if (!response.ok) {
    throw new Error('Failed to send password reset email');
  }

  return response.json();
};
